import state from './moduleProductGroupState';
import mutations from './moduleProductGroupMutations';
import actions from './moduleProductGroupActions';
import getters from './moduleProductGroupGetters';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
