import { ProductGroupMutation } from '@/store/mutationTypes';

export default {
  [ProductGroupMutation.ADD](state, item) {
    state.productGroups.unshift(item);
  },
  [ProductGroupMutation.SET](state, productGroups) {
    state.productGroups = productGroups;
  },
  [ProductGroupMutation.UPDATE](state, productGroup) {
    const index = state.productGroups.findIndex(
      (el) => el.id === productGroup.id
    );
    Object.assign(state.productGroups[index], productGroup);
  },
  [ProductGroupMutation.REMOVE](state, id) {
    const productGroups = state.productGroups.filter((el) => el.id !== id);
    state.productGroups = productGroups;
  },
};
