<template>
  <data-list-view-wrapper>
    <product-groups-sidebar
      v-if="$gate.can(['create_product_groups', 'edit_product_groups'])"
      :active="addNewDataSidebar"
      :data="sidebarData"
      @close="toggleDataSidebar"
    />

    <vx-table
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="productGroups"
      @selected="openData"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create
            v-if="$gate.can('create_product_groups')"
            title="Add group"
            @click="addNewData"
          />
        </div>

        <items-per-page
          :page="currentPage"
          :per-page="itemsPerPage"
          :total="productGroups.length"
          :queried-items="queriedItems"
          @change="changeItemsPerPage"
        />
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
            <vs-td>
              <p class="product-name">{{ tr.name }}</p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="$gate.can('edit_product_groups')"
                icon="EditIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                v-if="$gate.can('delete_product_groups')"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.name)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ProductGroupAction } from '@/store/actionTypes';

import dataListMixin from '@/mixins/dataListMixin';
import moduleProductGroup from '@/store/product-groups/moduleProductGroup';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import ItemsPerPage from '@/views/components/ItemsPerPage';
import ProductGroupsSidebar from '@/views/sidebars/ProductGroupsSidebar';
import VxTable from '@/components/vx-table/vxTable';

export default {
  mixins: [dataListMixin],
  components: {
    ButtonCreate,
    DataListViewWrapper,
    ItemsPerPage,
    ProductGroupsSidebar,
    VxTable,
  },
  computed: {
    ...mapState('product-group', {
      productGroups: ({ productGroups }) => productGroups,
    }),

    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.productGroups.length;
    },
  },
  methods: {
    ...mapActions('product-group', [
      ProductGroupAction.fetch,
      ProductGroupAction.remove,
    ]),

    registerStoreModules() {
      if (!moduleProductGroup.isRegistered) {
        this.$store.registerModule('product-group', moduleProductGroup);
        moduleProductGroup.isRegistered = true;
      }
    },
    addNewData() {
      if (this.$gate.can('create_product_groups')) {
        this.sidebarData = {};
        this.toggleDataSidebar(true);
      }
    },
    confirmDelete(id, name) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete "${name}"?`,
        accept: () => this.deleteData(id),
        acceptText: 'Delete',
      });
    },
    async deleteData(id) {
      if (this.$gate.can('delete_product_groups')) {
        this.$vs.loading();

        try {
          await this.removeProductGroup(id);

          this.notifySuccess(
            'Product group deleted',
            'The product group has been successfully deleted.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with deleting a group.');
        }

        this.$vs.loading.close();
      }
    },
    editData(data) {
      if (this.$gate.can('edit_product_groups')) {
        this.sidebarData = data;
        this.toggleDataSidebar(true);
      }
    },
    openData({ id }) {
      if (!this.$gate.can('view_product_groups')) {
        return;
      }

      this.$router.push({ name: 'product-group', params: { id } }).catch();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await this.fetchProductGroups();
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }
    this.$vs.loading.close();
  },
};
</script>
