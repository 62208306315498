import axios from '@/axios';
import { ProductGroupAction } from '@/store/actionTypes';
import { ProductGroupMutation } from '@/store/mutationTypes';

export default {
  [ProductGroupAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/product-groups', item)
        .then(({ data }) => {
          commit(
            ProductGroupMutation.ADD,
            Object.assign(item, { id: data.id })
          );
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductGroupAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/product-groups')
        .then(({ data }) => {
          commit(ProductGroupMutation.SET, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductGroupAction.fetchOne]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/product-groups/${id}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [ProductGroupAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/product-groups/${item.id}`, item)
        .then(({ data }) => {
          commit(ProductGroupMutation.UPDATE, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductGroupAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/product-groups/${id}`)
        .then(({ data }) => {
          commit(ProductGroupMutation.REMOVE, id);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [ProductGroupAction.addProduct]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/product-groups/${item.product_group_id}/products`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [ProductGroupAction.fetchByGroup](context, id) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/product-groups/${id}/products`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [ProductGroupAction.removeFromGroup](context, { groupId, productId }) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/product-groups/${groupId}/products/${productId}`)
        .then(resolve)
        .catch(reject);
    });
  },
};
